@font-face {
  font-family: "Metropolis";
  src: url("../assets/fonts/metropolis.thin.otf");
  font-weight: 300;
}

@font-face {
  font-family: "Metropolis";
  src: url("../assets/fonts/metropolis.regular.otf");
  font-weight: 400;
}

@font-face {
  font-family: "Metropolis";
  src: url("../assets/fonts/metropolis.medium.otf");
  font-weight: 500;
}

@font-face {
  font-family: "Metropolis";
  src: url("../assets/fonts/metropolis.semi-bold.otf");
  font-weight: 600;
}

@font-face {
  font-family: "Metropolis";
  src: url("../assets/fonts/metropolis.bold.otf");
  font-weight: 700;
}

@font-face {
  font-family: "Metropolis";
  src: url("../assets/fonts/metropolis.extra-bold.otf");
  font-weight: 800;
}

@font-face {
  font-family: "Metropolis";
  src: url("../assets/fonts/metropolis.black.otf");
  font-weight: 900;
}
