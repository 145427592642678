span.swiper-pagination-bullet {
  width: 16px;
  height: 16px;
}

.swiper-slide-thumb-active {
  opacity: 1;
}

div.swiper-button-prev {
  left: 0;
}

div.swiper-button-next {
  right: 0;
}

div.swiper-button-prev:after,
div.swiper-button-next:after {
  font-size: 1.5rem;
}

span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: black;
}

.swiper-pagination-custom > .swiper-pagination-bullet {
  margin: 0 4px;
}

.PhoneInputInput {
  border: none;
}

/* Hide arrows on number input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.box-shadow {
  box-shadow: 2px 4px 7px rgba(19, 24, 33, 0.09);
}

.PhoneInput {
  border: 1px solid rgb(209 213 219) !important;
  height: 2.5rem;
  border-radius: 0.25rem;
  padding-left: 0.6rem;
}

.PhoneInputInput {
  font-size: 10px;
  color: #1f1f1f;
  border-left: 1px solid rgb(209 213 219) !important;
  height: 100%;
  padding-left: 0.6rem;
}

input:focus-visible {
  outline-offset: 0;
  outline: none !important;
}

.qr-code-shadow {
  box-shadow: 0px 2.317124843597412px 55.61099624633789px 0px #f2482240;
}

.sidebar::-webkit-scrollbar {
  width: 4px;
}

/* React Select */
.react-select__input > input:focus {
  @apply border-transparent focus:border-transparent focus:ring-0;
}

/* Track */
.sidebar::-webkit-scrollbar-track {
  border-radius: 8px;
}

/* Handle */
.sidebar::-webkit-scrollbar-thumb {
  background: #d2d2d2;
  border-radius: 8px;
}

/* Handle on hover */
.sidebar::-webkit-scrollbar-thumb:hover {
  background: #898989;
}
